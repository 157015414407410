import React, { useMemo } from 'react'
import { Button, Typography, Icon, useTheme } from '@wicadu/arepa/ui'
import styled from '@emotion/styled'

import useTemplate from '@hooks/useTemplate'

const texts = {
  help_center: 'Ir a nuestro centro de ayuda'
}

function FeedbackToUserAction () {
  const { onNavigate, type, title, description, callToActionText } = useTemplate()
  const { colors } = useTheme()

  const icons = useMemo(() => ({
    success: 'fas fa-check-circle',
    error: 'fas fa-times-circle',
    question: 'fas fa-question-circle',
    warning: 'fas fa-exclamation-circle'
  }), [])

  const hideHelpCenter = useMemo(() => !['question', 'error'].includes(type), [type])

  const helpCenter = process.env.HELP_WEBAPP_HOST
  const url = process.env.OAUTH_WEBAPP_HOST

  return (
    <Wrapper className='row center-xs middle-xs'>
      <Content className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>

        <Icon name={icons[type]} size={70} color={colors[type]} />

        <Typography type='title' weight={700} align='center'>{title}</Typography>

        {description?.map((parragraph, index) => (
          <Typography key={index} type='description' size={20} align='center'>{parragraph}</Typography>
        ))}

        <div className='row center-xs center-sm middle-xs'>
          <Button size='medium' data-url={url} onClick={onNavigate}>{callToActionText}</Button>
          {!hideHelpCenter && (
            <Button type='link' data-url={helpCenter} onClick={onNavigate}>
              {texts.help_center}
            </Button>
          )}
        </div>
      </Content>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  & {
    margin: 0;
    height: 100vh;
  }
`

const Content = styled.div`
  & > * {
    margin: 16px 0;
  }

  & > div > * {
    margin: 8px;
  }
`

const HelpCenter = styled.div`
  ${({ hide }) => hide && `display: none;`}
`

export default FeedbackToUserAction
